import React, { useCallback, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { format, parseISO, differenceInDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Container, Live, ModalPlan, FloatValidation } from './styles';

interface INoticeHomeModalProps {
  showDoNotShowAgain: boolean;
}


const NoticeHomeModal: React.FC<INoticeHomeModalProps> = ({ showDoNotShowAgain }) => {
  const { user } = useAuth();
  const history = useHistory();

  // alert magic-key constaint
  const LOCAL_STORAGE_KEY_ALERT = '@poxalulu:aviso-notas';

  // modal plans
  const openModalExtendsPlan = useCallback((email: string) => {
    const modal = withReactContent(Swal);
    modal.fire({
      html: (
        <ModalPlan>
          <div>
            <div onClick={() => Swal.close()}></div>
          </div>
          <a href={`https://checkout.poxalulu.com.br/3c79eda7-4fc5-4131-a959-a73b59aa385e?coupon=BLACKNOVEMBER2023`} target="_blank" rel="noreferrer">
            <img
              src="https://poxalulu-api.s3.sa-east-1.amazonaws.com/geral/promo22.jpg"
              alt="Renovar plano"
            />
          </a>
          <footer>
            {user?.courses?.length > 0 ? (
              <FloatValidation style={{ background: '#f9f9f91c' }}>Seu plano termina em {user?.courses?.length > 0 && format(parseISO(user.courses[0].end_date), "dd'/'MM'/'Y", { locale: ptBR })}</FloatValidation>
            )
              :
              (
                <FloatValidation style={{ background: '#ff141494' }}>Você não tem um plano ativo</FloatValidation>
              )}
          </footer>
        </ModalPlan>
      ),
      background: 'transparent',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: false,
      width: '550px',
    });
  }, [user.courses]);


  const handleNotShowAlert = () => {
    if (!localStorage.getItem(LOCAL_STORAGE_KEY_ALERT)) {
      localStorage.setItem(LOCAL_STORAGE_KEY_ALERT, 'true');
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY_ALERT);
    }
  }

  //AVISO MODAL HOME
  useEffect(() => {
    if ((differenceInDays(parseISO(user.courses[0]?.end_date), new Date()) <= 60)) {
      openModalExtendsPlan(user?.email);
    }
    else {
      if (!localStorage.getItem(LOCAL_STORAGE_KEY_ALERT)) {
        const liveLink = 'NDhzKff6ZBM7711';
        const modal = withReactContent(Swal);
        modal.fire({
          html: (
            <Live>
              <div>
                <p style={showDoNotShowAgain ? { display: 'flex' } : { display: 'none' }}>
                  <input type="checkbox" onChange={handleNotShowAlert} /> Não exibir novamente
                </p>
                <div onClick={() => Swal.close()}></div>
              </div>
              {/* <iframe
                  src={`https://www.youtube.com/embed/${liveLink}?autoplay=1`}
                  frameBorder="0"
                  title="Live"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  height="260px"
                  width="100%"
                /> */}

                <img
                  src="https://github.com/user-attachments/assets/5129867d-1390-4e1b-9bc7-9e5678ac78ad"
                  alt="Aviso principal - Plataforma Poxalulu"
                  //onClick={() => { /*history.push('/intensivao');*/ Swal.close(); }}
                  onClick={() => window.open('https://forms.gle/kfi6KS8TNL5wHtfK9', '_blank')}
                  style={{ cursor: 'pointer' }}
                />
            </Live>
          ),
          background: 'transparent',
          backdrop: 'rgba(110, 97, 198, .1)',
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: false,
          width: '550px',
        });
      }
    }

  }, [history, openModalExtendsPlan, user.email, showDoNotShowAgain, user.courses]);

  return (
    <Container />
  );
}

export default NoticeHomeModal;

const menuLinks = [
  // SECTION 1 ==================================
  {
    section: 1,
    location: "perfil",
    linkName: "Informações pessoais",
    target: "_self",
  },
  {
    section: 1,
    location: "minhas-conquistas",
    linkName: "Minhas conquistas",
    target: "_self",
  },
  /*  {
    "section": 1,
    "location": "preferencias",
    "linkName": "Preferências",
    "target": "_self",
  }, AQUI 777*/
  // SECTION 2 ==================================
  {
    section: 2,
    location: "notificacoes",
    linkName: "Visualizar notificações",
    target: "_self",
  },
  // SECTION 3 ==================================
  {
    section: 3,
    location: "alterar-senha",
    linkName: "Alterar senha",
    target: "_self",
  },
  {
    section: 3,
    location: "historico-de-acessos",
    linkName: "Historico de acessos",
    target: "_self",
  },
  {
    section: 3,
    location: "https://poxalulu.com.br/politica-de-privacidade",
    linkName: "Política de privacidade",
    target: "_blank",
  },
  {
    section: 3,
    location: "https://poxalulu.com.br/termos-de-uso",
    linkName: "Termos de uso",
    target: "_blank",
  },
];

export default menuLinks;
